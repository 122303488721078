import * as React from 'react';
import classNames from 'classnames';
import Title from './Title';
import Paragraph from './Paragraph';
import { ConfigConsumer } from '../config-provider';
import Element from './Element';
import SkeletonAvatar from './Avatar';
import SkeletonButton from './Button';
import SkeletonInput from './Input';
import SkeletonImage from './Image';
function getComponentProps(prop) {
    if (prop && typeof prop === 'object') {
        return prop;
    }
    return {};
}
function getAvatarBasicProps(hasTitle, hasParagraph) {
    if (hasTitle && !hasParagraph) {
        // Square avatar
        return { size: 'large', shape: 'square' };
    }
    return { size: 'large', shape: 'circle' };
}
function getTitleBasicProps(hasAvatar, hasParagraph) {
    if (!hasAvatar && hasParagraph) {
        return { width: '38%' };
    }
    if (hasAvatar && hasParagraph) {
        return { width: '50%' };
    }
    return {};
}
function getParagraphBasicProps(hasAvatar, hasTitle) {
    const basicProps = {};
    // Width
    if (!hasAvatar || !hasTitle) {
        basicProps.width = '61%';
    }
    // Rows
    if (!hasAvatar && hasTitle) {
        basicProps.rows = 3;
    }
    else {
        basicProps.rows = 2;
    }
    return basicProps;
}
const Skeleton = (props) => {
    const renderSkeleton = ({ getPrefixCls, direction }) => {
        const { prefixCls: customizePrefixCls, loading, className, style, children, avatar, title, paragraph, active, round, } = props;
        const prefixCls = getPrefixCls('skeleton', customizePrefixCls);
        if (loading || !('loading' in props)) {
            const hasAvatar = !!avatar;
            const hasTitle = !!title;
            const hasParagraph = !!paragraph;
            // Avatar
            let avatarNode;
            if (hasAvatar) {
                const avatarProps = Object.assign(Object.assign({ prefixCls: `${prefixCls}-avatar` }, getAvatarBasicProps(hasTitle, hasParagraph)), getComponentProps(avatar));
                // We direct use SkeletonElement as avatar in skeleton internal.
                avatarNode = (<div className={`${prefixCls}-header`}>
            <Element {...avatarProps}/>
          </div>);
            }
            let contentNode;
            if (hasTitle || hasParagraph) {
                // Title
                let $title;
                if (hasTitle) {
                    const titleProps = Object.assign(Object.assign({ prefixCls: `${prefixCls}-title` }, getTitleBasicProps(hasAvatar, hasParagraph)), getComponentProps(title));
                    $title = <Title {...titleProps}/>;
                }
                // Paragraph
                let paragraphNode;
                if (hasParagraph) {
                    const paragraphProps = Object.assign(Object.assign({ prefixCls: `${prefixCls}-paragraph` }, getParagraphBasicProps(hasAvatar, hasTitle)), getComponentProps(paragraph));
                    paragraphNode = <Paragraph {...paragraphProps}/>;
                }
                contentNode = (<div className={`${prefixCls}-content`}>
            {$title}
            {paragraphNode}
          </div>);
            }
            const cls = classNames(prefixCls, {
                [`${prefixCls}-with-avatar`]: hasAvatar,
                [`${prefixCls}-active`]: active,
                [`${prefixCls}-rtl`]: direction === 'rtl',
                [`${prefixCls}-round`]: round,
            }, className);
            return (<div className={cls} style={style}>
          {avatarNode}
          {contentNode}
        </div>);
        }
        return children;
    };
    return <ConfigConsumer>{renderSkeleton}</ConfigConsumer>;
};
Skeleton.defaultProps = {
    avatar: false,
    title: true,
    paragraph: true,
};
Skeleton.Button = SkeletonButton;
Skeleton.Avatar = SkeletonAvatar;
Skeleton.Input = SkeletonInput;
Skeleton.Image = SkeletonImage;
export default Skeleton;
